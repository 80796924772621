html,
body {
	height: 100%;
}

body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#map-stats-container {
	height: calc(100vh - 64px);
}

a {
	color: #007aff;
}
.search-bar {
	width: 22rem;
}

@media screen and (max-width: 40em) {
	.search-bar {
		width: 100%;
	}

	#map-stats-container {
		height: calc(100vh - 64px);
	}
}

.pink {
	color: rgb(255, 45, 85);
}

.light-blue {
	color: rgb(90, 200, 250);
}

.node-image {
	height: 3rem;
	width: 3rem;
}

.mh-100 {
	max-height: 100%;
}

.mv05 {
	margin-top: 0.125rem;
	margin-bottom: 0.125rem;
}

.min-h2 {
	min-height: 2rem;
}

.min-w2 {
	min-width: 2rem;
}

.max-h-5-rows {
	max-height: 246px;
}

.pointer-events-none {
	pointer-events: none;
}

.pointer-events-all {
	pointer-events: all;
}

.zoom-in {
	cursor: zoom-in;
}

.on:focus {
	outline: none;
}

.blue {
	color: #007aff !important;
}

.bg-map-beige {
	background-color: rgb(229, 227, 223);
}
